import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppLoad} from '../../../app/app.load';
import { ErrorService } from 'src/app/Common/errorService';


@Injectable({
  providedIn: 'root'
})
export class ActivationService {

  constructor(private _http: HttpClient,
    private appLoad: AppLoad,private errorService: ErrorService
     ) {
  }

  continueActivate(data:any): Observable<any>{
   
      let body = {
        regno: data.SubscriptionNo,
        lastName: data.LastName,
        postal:data.Postal
      };
      return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl+"account/verifyActivateWithPostal" , body)
        .pipe(
          catchError(this.errorService.handleError)
        );
    }
    isUserAvailable(username:any): Observable<any>{
   
      let body = JSON.stringify(username);
         return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl+"account/available/username" , body)
           .pipe(
             catchError(this.errorService.handleError)
           );
       }
   
       GetProfileByRegno(regno:any){
         
         return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl+"Account/ProfileByRegno?regno="+regno)
         .pipe(
           catchError(this.errorService.handleError)
         );
   
       }
   
       Activate(data:any,info:any): Observable<any>{
         
         let body = {
           regno: info.regno,
           lastName: info.lastname,
           postal:info.postal,
           username: data.username,
           password:data.password,
           email:data.email
         };
            return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl+"Account/activatewithpostal" , body)
              .pipe(
                catchError(this.errorService.handleError)
              );
          }

  }



