import { ErrorService } from './../../Common/errorService';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppLoad } from '../../app.load';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient,
    private appLoad: AppLoad,
    private errorService: ErrorService) { }
  
  GetProfile(): Observable<any> {
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl + 'account/profile')
      .pipe(       
        catchError(this.errorService.handleError)
      );
  }

  getContact(username:any): Observable<any>{
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl+"account/contact1?username="+username+"")
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getProfileImg(username:string,Regno:string): Observable<any>{
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl +"account/getProfileImage?username="+username)
    .pipe(
      catchError(this.errorService.handleError)
    );
  }

  uploadImg(files:any,username:string)
  {
    let formData: FormData = new FormData();
    formData.append("uploadFile", files.item(0));
    let httpHeaders = new HttpHeaders({
      "Username":username,
      "SiteID":this.appLoad.appSettings.commonSettings.siteId1,
      "SiteUrl":this.appLoad.appSettings.commonSettings.siteUrl,
    });
    let options = {
      headers: httpHeaders
    };
    return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl +"account/uploadFile", formData,options).pipe(
      catchError(this.errorService.handleError)
    );
  }

  ChangeAddress(body:any): Observable<any> {
    return this._http.put(this.appLoad.appSettings.commonSettings.CfmhApiUrl +"account/changeAddress", body)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  ChangeContact(body:any): Observable<any> {
    return this._http.put(this.appLoad.appSettings.commonSettings.CfmhApiUrl +"account/changeContact",body)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  GetSecurityQuestions(): Observable<any> {
    return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl + 'account/questions', JSON.stringify("Bole3004"))
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  ChangeSecurityQuestion(body: any): Observable<any> {
    return this._http.patch(this.appLoad.appSettings.commonSettings.CfmhApiUrl +"account/security/question", body)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  
  ResetPassword(body: any): Observable<any> {
    return this._http.put(this.appLoad.appSettings.commonSettings.CfmhApiUrl + "Account/ChangePassword", body)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
