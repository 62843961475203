import { AppLoad } from './../../app.load';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from '../../Common/errorService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/common/logging.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: "root"
})
export class LoginService {
  error: string = "";

  constructor(private _http: HttpClient,
    private appLoad: AppLoad,
    private errorService: ErrorService,
    private sanitizer:DomSanitizer) { }

  PerformLogin(data) {
    this.LoginUser(data);
  }

  LoginUser(data) {
    this.Login(data).subscribe(res => {
      let loginSessiondata: any = {}
      loginSessiondata.loginDetails = res;
      this.appLoad.timedOut = true;
      this.appLoad.SetSession(loginSessiondata);
    }, error => {
      this.error = error;
    }, () => {
      this.GetUserProfile(data);
    });
  }

  GetUserProfile(data) {
    this.GetProfile().subscribe(res => {
      let profileSessiondata: any = {}
      profileSessiondata.loginDetails = this.appLoad.GetSession(this.appLoad.appSession).loginDetails;
      profileSessiondata.profileDetails = res;
      this.appLoad.SetSession(profileSessiondata);
    }, error => {
      this.error = error;
    }, () => {
      this.GetContactDetails(this.appLoad.GetSession(this.appLoad.appSession).loginDetails.userName,data);
    })
  }

  GetContactDetails(username,data) {
    this.getContact(username).subscribe(res => {
      let contactSessiondata: any = {}
      contactSessiondata.loginDetails = this.appLoad.GetSession(this.appLoad.appSession).loginDetails;
      contactSessiondata.profileDetails = this.appLoad.GetSession(this.appLoad.appSession).profileDetails;
      contactSessiondata.contactDetails = res;
      this.appLoad.SetSession(contactSessiondata);
    }, error => {
      this.error = error;
    }, () => {
      this.GetProfileImage(data);
    });
  }

  GetProfileImage(data) {
    let regNo = this.appLoad.GetSession(this.appLoad.appSession).profileDetails.OdbUser.RegNo;
    let username = this.appLoad.GetSession(this.appLoad.appSession).loginDetails.userName;
    this.getProfileImg(username, regNo).subscribe(res => {
      if (res != null) {
        let img64 = res;
        this.appLoad.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(img64);
      } else {
        this.appLoad.profileImage = "../../../assets/appIcon/default-profile-img.svg";
      }
    }, error => {
      this.appLoad.profileImage = "../../../assets/appIcon/default-profile-img.svg";
      this.error = error;
    }, () => {
      if (data.rememberMe)
        localStorage.setItem("userId", data.Username);
      else
        localStorage.removeItem("userId");
      this.appLoad.NavigateToUrl("member/dashboard");
      localStorage.setItem("timedOut", JSON.stringify(true));
    });
  }

  Login(data: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      "Client_id": this.appLoad.appSettings.commonSettings.clientId,
      "Installation_id": this.appLoad.appSettings.commonSettings.installationId,
    });
    let options = {
      headers: httpHeaders
    };
    return this._http.post(this.appLoad.appSettings.commonSettings.CfmhApiUrl + 'account/login', data, options)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  GetProfile(): Observable<any> {
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl + 'account/profile')
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getContact(username: any): Observable<any> {
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl + "account/contact1?username=" + username + "")
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  getProfileImg(username: string, Regno: string): Observable<any> {
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl + "account/getProfileImage?username=" + username)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  GetDocuments(): Observable<any> {
    return this._http.get(this.appLoad.appSettings.commonSettings.CfmhApiUrl + "document?page=1&pageSize=1000")
      .pipe(
        // map((lessons:any) => this.SortData(lessons)),
        catchError(this.errorService.handleError)
      );
  }
}
